import { defineStore } from 'pinia';
import Bus from '@/Bus';
import { projectVersions, orderItemPaymentStatuses, orderItemTypes, orderStages, orderPaymentStatuses } from '@/Models';
import CartsService from '@/Services/CartsService';
import { apiClient } from '@/Clients/ApiClient.js';
import { getTotalProductQuantity } from '@/Utils/ProductUtility';
import { useProjectContextStore } from '@/Stores/Web/projectContextStore';
import { useProjectUserContextStore } from '@/Stores/Web/projectUserContextStore';
import { isPledgeImplicitEditMode } from '@/Utils/PledgeUtility';

const { v2 } = projectVersions;
const { product, productSet, tip } = orderItemTypes;
const cartService = new CartsService(apiClient);

export const useCartSummaryStore = defineStore({
    id: 'cartSummary',
    state: () => ({
        cartSummary: {
            backerNumber: 0,
            canEditPledgedItems: false,
            canProceedToCheckoutValidationResult: {
                isValid: false,
                message: null,
            },
            canCancelPledge: false,
            cancelOrderUrl: null,
            cart: null,
            cartDetailsUrl: '',
            checkoutUrl: '',
            itemsCount: 0,
            productOrderItems: null,
            productsCount: 0,
            projectsCount: 0,
            totalQuantity: 0,
            totalValueConverted: 0,
        },
        cartCandidate: null,
    }),
    actions: {
        async loadCartSummary(projectID, preventCartLoading) {
            if (preventCartLoading) {
                return Promise.resolve(true);
            }
            else {
                return await cartService
                    .getCartSummary(projectID)
                    .then(response => {
                        if (projectID) {
                            this.cartSummary = response.data.cart;
                            this.cartCandidate = response.data.cartCandidate;
                        }
                        else {
                            this.cartSummary = response.data;
                        }
                    })
                    .catch(_ => {
                        return Promise.resolve(true);
                    });
            }
        },
        async updateCartSummary(projectID) {
            return await cartService
                .getCartSummary(projectID)
                .then(response => {
                    this.cartSummary = response.data.cart;
                    this.cartCandidate = response.data.cartCandidate;
                    Bus.Topics.Web.Notify.CartModified.publish();
                });
        },
    },
    getters: {
        getAvailableQuantityFromPledge: state => ({ productID, remainingStockLimit, deductQuantityFromCurrentItems }, projectVersion) => {
            if (remainingStockLimit === null)
                return null;

            let quantityAvailable = 0;
            const cartSummary = state.cartSummary;

            // In V2 we should include quantity used in editing pledge
            if (projectVersion === v2 && cartSummary?.cart?.parentOrder) {
                quantityAvailable = getTotalProductQuantity(cartSummary.cart.parentOrder.orderItems, false, productID);
            }

            if (cartSummary?.cart) {
                let currentCartQuantity = 0;
                //  deduct quantity from all items
                if (deductQuantityFromCurrentItems) {
                    currentCartQuantity = getTotalProductQuantity(cartSummary.cart.orderItems, false, productID);
                }
                // deduct quantity from paid items (non editable)
                else if (cartSummary.cart.parentOrder?.orderStage === orderStages.placed
                        && cartSummary.cart.parentOrder?.orderPaymentStatus === orderPaymentStatuses.paid
                        && !cartSummary.canEditPledgedItems) {
                    currentCartQuantity = getTotalProductQuantity(cartSummary.cart.orderItems, true, productID);
                }
                quantityAvailable -= currentCartQuantity;
            }

            return quantityAvailable + remainingStockLimit;
        },
        isPledgePlaced: state => {
            return !!state.cartSummary?.cart?.parentOrder || state.cartCandidate !== null;
        },
        cartSummaryUnpaidItems: state => {
            if (state.cartSummary?.cart) {
                return state.cartSummary?.cart?.orderItems
                    .filter(obj => {
                        const isStatusUnpaid = obj.paymentStatus === orderItemPaymentStatuses.unpaid;
                        const isValidType = [
                            product,
                            tip,
                            productSet,
                        ].includes(obj.orderItemType);

                        return isStatusUnpaid && isValidType;
                    });
            }
            return null;
        },
        getCurrentPledge: state => (projectVersion, orderAwaitingPayment) => {
            if (projectVersion === v2
                && orderAwaitingPayment) {
                return orderAwaitingPayment;
            }
            return state.cartSummary?.cart?.orderID === 0 && state.cartCandidate ? state.cartCandidate : state.cartSummary;
        },
        isPledgeEditable: state => orderAwaitingPaymentCanEditPledgedItems => {
            return orderAwaitingPaymentCanEditPledgedItems === true || state.cartCandidate?.canEditPledgedItems === true;
        },
        isPledgeImplicitEditMode: state => {
            const { projectContext } = useProjectContextStore();
            const orderStage = state.currentPledge.cart?.parentOrder?.orderStage;
            const orderPaymentStatus= state.currentPledge.cart?.parentOrder?.orderPaymentStatus;

            if (projectContext.project && orderStage && orderPaymentStatus) {
                return isPledgeImplicitEditMode(projectContext.project, orderStage, orderPaymentStatus);
            }

            return false;
        },
        canAddNewItemsToPledge: state => orderAwaitingPaymentCanAddNewItems => {
            return orderAwaitingPaymentCanAddNewItems === true || state.cartCandidate?.cart.canAddNewItems === true;
        },
        hasAnyOrder: state => {
            const cartSummary = state.cartSummary?.cart?.parentOrder ? state.cartSummary : state.cartCandidate;

            if (cartSummary) {
                const validStates = [
                    { stage: orderStages.pledged, paymentStatus: orderPaymentStatuses.paid },
                    { stage: orderStages.placed, paymentStatus: orderPaymentStatuses.paid },
                    { stage: orderStages.pledged, paymentStatus: orderPaymentStatuses.scheduled },
                    { stage: orderStages.placed, paymentStatus: orderPaymentStatuses.scheduled }];
                return validStates.some(x => x.stage === cartSummary.cart.orderStage && x.paymentStatus === cartSummary.cart.orderPaymentStatus)
                    || validStates.some(x => x.stage === cartSummary.cart.parentOrder?.orderStage && x.paymentStatus === cartSummary.cart.parentOrder?.orderPaymentStatus);
            }

            return false;
        },
        currentPledge: state => {
            const { projectContext } = useProjectContextStore();
            const { orderAwaitingPayment } = useProjectUserContextStore();

            return projectContext.hasProject
                ? state.getCurrentPledge(projectContext.project.version, orderAwaitingPayment)
                : null;
        },
        productOrderItems: state => {
            return state.currentPledge?.productOrderItems ?? [];
        },
        tipsOrderItems: state => {
            const { tip } = orderItemTypes;
            return state.currentPledge?.cart?.orderItems?.filter(item => item.orderItemType === tip) ?? [];
        },
        discountsOrderItems: state => {
            const { discount } = orderItemTypes;
            return state.currentPledge?.cart?.orderItems?.filter(item => item.orderItemType === discount) ?? [];
        },
        currencySymbol: state => {
            return state.currentPledge?.cart?.currencySymbol;
        },
    },
});