var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_pa-3 _tac _flexcol _gap-2 _ai-c" },
    [
      _vm._v(
        "\n    " +
          _vm._s(_vm.Resources.Layout.WebPushBlockedParagraph) +
          "\n    "
      ),
      _c(
        "base-button",
        {
          staticClass: "_ttl",
          attrs: {
            tag: "a",
            theme: "accent",
            target: "_blank",
            href: _vm.url,
            "data-qa": "notification-box-button:ReadInstructions",
          },
          on: { click: _vm.onButtonClick },
        },
        [_vm._v(_vm._s(_vm.Resources.Layout.WebPushHowToUnblockLink))]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "gfu-btn__text _tc--accent",
          on: { click: _vm.onMessageHide },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.Resources.Layout.HideThisMessageButton) +
              "\n    "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }