var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_pa-3 _tac _flexcol _ai-c _gap-2" },
    [
      _c("span", [_vm._v(_vm._s(_vm.Resources.Layout.WebPushEnableParagraph))]),
      _vm._v(" "),
      _c(
        "base-button",
        {
          staticClass: "_ttl",
          attrs: {
            theme: "accent",
            "data-qa": "notification-box-button:EnablePushNotifications",
          },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.onEnablePush.apply(null, arguments)
            },
          },
        },
        [_vm._v(_vm._s(_vm.Resources.Layout.WebPushEnableButton))]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "gfu-btn__text _tc--accent",
          on: { click: _vm.onMessageHide },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.Resources.Layout.HideThisMessageButton) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "gfu-bt gfu-bt--caption _tc--lighter" },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.Resources.Layout.WebPushAdjustPreferencesParagraph) +
              "\n        "
          ),
          _c(
            "base-link",
            {
              staticClass: "_tc--accent",
              attrs: {
                url: _vm.subscriptionManagmentUrl,
                "data-qa": "notification-box-button:CommunicationPreferences",
              },
              on: { click: _vm.onLinkClick },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.Resources.Layout.CommunicationPreferencesLink) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(".\n    "),
        ],
        1
      ),
      _vm._v(" "),
      _c("progress-overlay", {
        attrs: { visible: _vm.isProcessing, sticky: "" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }